.gray-bg {
  background-color: #f5f5f5;
}
/* Contact Us
---------------------*/
.contact-name {
  margin-bottom: 30px;
}
.contact-name h5 {
  font-size: 22px;
  color: #5c5d5e;
  margin-bottom: 5px;
  font-weight: 600;
}
.contact-name p {
  margin: 0;
  color: #6c757d !important;
}

.social-share a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
}
.social-share .dribbble {
  box-shadow: 0 8px 30px -4px rgba(234, 76, 137, 0.5);
  background-color: #ea4c89;
}
.social-share .behance {
  box-shadow: 0 8px 30px -4px rgba(0, 103, 255, 0.5);
  background-color: #0067ff;
}
.social-share .linkedin {
  box-shadow: 0 8px 30px -4px rgba(1, 119, 172, 0.5);
  background-color: #0177ac;
}

.contact-form .form-control:focus {
  border-bottom: 1px solid #fc5356;
}
.contact-form .form-control.invalid {
  border-bottom: 1px solid #ff0000;
}

@media (max-width: 767px) {
  .services-2 .text {
    width: 100%;
    position: relative;
    right: 3%;
  }
  .navbar-brand img {
    width: 70px;
  }
  .wrap {
    display: none;
  }
  .listItem {
    border-bottom: 1px solid black;
  }
  .bg-cove {
    height: 500px;
    background-color: #ea4c89;
  }
}

iframe {
  display: block;
  width: 90%;
  max-width: 60rem;
  height: 25em;
  margin: 2rem auto;
  border: 1px solid black;
}
