.carousel-caption h1 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  line-height: 1.3;
}

.carousel-caption {
  position: absolute;
  top: 15%;
}
.carousel-item img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}
.bgColor {
  background-color: #a69279;
  color: aliceblue;
  padding: 5px;
}
.animate__animated.animate__bounceInLeft {
  --animate-duration: 3s;
}
@media (max-width: 576px) {
  .carousel-item img {
    height: 100%;
  }
}
