.headerContainerText {
  position: relative;
  top: 40%;
  z-index: 3;
}
h1 {
  font-size: 2vw;
  color: #fff;
  background-color: black;
}
.imageOverLay {
  height: 700px;
}
.bgColor {
  background-color: #a69279;
  color: aliceblue !important;
  padding: 5px;
}
.heroImage {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.heroImage h1 {
  font-size: 2vw;
}
@media only screen and (max-width: 900px) {
  .headerContainerText {
    top: 10%;
  }
  .imageOverLay {
    height: 250px !important;
  }
  .imageOverLay h1 {
    font-size: 12px;
    font-weight: 500;
  }
  .imageOverLay h2 {
    font-size: 12px;
    font-weight: 500;
  }
}
@media (max-width: 576px) {
  .jumbotron {
    background-size: cover;
  }
}
