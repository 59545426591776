.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.wrappertext {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 60%;
  margin: 2px auto;
  text-align: center;
  background-color: #f9f7f7;
}

@media screen and (max-width: 900px) {
  .wrappertext {
    padding: 3px;
    width: 100%;
  }
  .wrapper {
    height: auto;
  }
}
