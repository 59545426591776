.impressum_wrapper {
  display: flex;
  gap: 5;
  justify-content: center;
  justify-items: left;
  text-align: start;
  flex-direction: row;
  padding: 20px;
  width: 80%;
  margin: auto;
}
.impressum_wrapper .impressum_wrapper_left {
  width: 40%;
  padding: 30px;
}
.impressum_wrapper .impressum_wrapper_right {
  width: 60%;
  padding: 30px;
}
@media only screen and (max-device-width: 900px) {
  .impressum_wrapper {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }
  .impressum_wrapper .impressum_wrapper_left {
    width: 100%;
    padding: 2px;
  }
  .impressum_wrapper .impressum_wrapper_right {
    width: 100%;
    padding: 2px;
  }
}
