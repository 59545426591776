.contactFormWrapper {
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 10px;
  flex-direction: column;
  background-color: #f9f7f7;
}
.left {
  width: 40%;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  border-left: 1px solid rgb(235, 231, 231);
}
.form {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 10px;
  width: 100%;
}
.form input {
  min-width: 100% !important;
  border: 0.5px solid #a69279;
  border-radius: 5px;
  height: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
}
.form .buttonWrapper {
  align-self: flex-start;
  width: 150px !important;
}
.buttonWrapper button {
  width: 100%;
  height: 40px;
  border: none;
  background-color: #a69279;
  color: #f4f2f1;
  border-radius: 5px;
}
.form button:hover {
  background: #703e1beb !important ;
}
.form input:focus,
.form textarea:focus {
  outline: none;
  background-color: #f4f2f1;
  color: #717171;
}
.form textarea {
  border: 0.5px solid #a69279;
  height: 90px;
  width: 400px;
  border-top: none;
  border-left: none;
  border-right: none;
}
.leftRightWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.mainTitile {
  margin: 20px auto;
}
h2 {
  color: #a69279;
}
.inquiry {
  padding: 30px;
  width: 80%;
}
.ImageWrapper {
  width: 100%;
  padding: 30px;
}
.ImageWrapper img {
  width: 100%;
  height: 98vh;
  border-radius: 5px;
  border: 1px solid #a69279;
}
@media (max-width: 767px) {
  .leftRightWrapper {
    flex-direction: column;
    width: 100%;
  }
  .inquiry {
    width: 100%;
    padding: 10px;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
  .form textarea {
    width: 100%;
  }
  .ImageWrapper {
    padding: 2px;
  }
  .ImageWrapper img {
    height: 60vh;
  }
}

.isButtonDisabled {
  pointer-events: none;
  width: 150px;
}
.isButtonDisabled button {
  width: 100%;
  height: 40px;
  border: none;
  background-color: #ccc;
  color: #f4f2f1;
  border-radius: 5px;
}
.green {
  color: green;
}
.red {
  color: red;
}
