@media screen and (max-width: 900px) {
  .cards {
    flex-direction: column;
    width: 100% !important;
  }
}

.title {
  margin: 0;
  border: none;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  width: 30%;
}

.card {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  background-color: #fff;
  border: 1px solid #a69279;
  border-radius: 5px;
  width: 90%;
  height: auto;
}
.cardImage {
  margin: 0;
}
.cardImage img {
  max-width: 100%;
  border-radius: 3px 3px 0 0;
}

.cardText {
  justify-self: flex-start;
  margin: 0;
  padding: 1rem;
}
.cardInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 1rem;
}
.cardInner i {
  align-self: center;
}
.cardInner button {
  padding: 10px 20px;
  font-size: 0.85rem;
  color: white;
  background-image: radial-gradient(circle, #cc8400, #c20000);
  background-size: 400% 100%;
  background-position: top right;
  border: none;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}
.cardInner button:hover {
  background-image: radial-gradient(circle, #cc8400, #c20000);
  background-position: center;
  cursor: pointer;
}
