.projectIemsWrapper {
  display: flex;
  width: 350px;
  justify-content: space-between;
  margin: 50px auto;
}

.projectIemsWrapper > * {
  border: 1px solid rgb(136, 133, 133);
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.projectIemsWrapper > *:hover {
  background-color: black;
  color: #ffff;
}
.active {
  background-color: #a69279;
  color: #ffff;
}

.cardWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.card {
  border: 2px solid rgb(136, 133, 133);
  width: 400px;
  height: 354px;
  margin: 2px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.imageWrapper {
  width: 400px;
  height: 260px;
}
.imageWrapper img {
  width: 100%;
  height: 100%;
  border-radius: 21px;
}

.isMouseOver {
  width: 400px;
  height: 260px;
  transition: all 0.9s ease-out;
  background-color: rgb(53, 53, 53);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  color: white;
}
.isMouseLeave {
  border: 2px solid black;
  width: 400px;
  height: 260px;
  display: flex;
  justify-content: center;
  border-radius: 21px;
  transition: all 0.9s ease-out;
}

.isMouseOver:hover {
  transform: scale(1.5);
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .isMouseOver {
    width: 380px;
    height: 262px;
    margin: 10px;
  }
  .isMouseLeave {
    width: 336px;
    height: 262px;
    margin: 10px;
  }
  .isMouseOver:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
