.cardBox {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  transition: 0.5s;
  margin: 20px auto;
  background-color: #f9f7f7;
}
@media screen and (max-width: 900px) {
  .cardBox {
    flex-direction: column;
    margin: 1px;
    padding: 1px;
  }
}
