.footer {
  padding: 0;
  overflow: hidden;
  background: #252525;
}
.footer a {
  color: rgb(255 193 7);
}
.footer p {
  color: rgba(255, 255, 255, 0.3) !important;
  font-size: 14px;
}
.footer .footer-heading {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.footer ul.list-unstyled li a {
  color: rgba(255, 255, 255, 0.5);
}
.footer .form-consultation {
  width: 100%;
}
.footer .form-consultation .form-control {
  height: 44px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.3) !important;
  font-size: 14px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}
.footer .form-consultation .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.3) !important;
}
.footer .form-consultation .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.3) !important;
}
.footer .form-consultation .form-control:-ms-input-placeholder {
  /* IE 1255+ */
  color: rgba(255, 255, 255, 0.3) !important;
}
.footer .form-consultation .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.3) !important;
}
.footer .form-consultation .form-control:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer .form-consultation .form-control:focus,
.footer .form-consultation .form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer .form-consultation .submit {
  color: #fff !important;
}
.footer .form-consultation textarea.form-control {
  height: inherit !important;
}
.footer .aside-stretch-right {
  background: #333333;
}
.footer .aside-stretch-right:after {
  background: #333333;
}
@media (max-width: 767.98px) {
  .footer .aside-stretch-right {
    background: transparent;
  }
  .footer .aside-stretch-right:after {
    background: transparent;
    display: none;
  }
}
